// React
import React from "react";

// Components
import NavAppBar from "./NavAppBar";
import {AuthUserContext} from "../Session";

export default function AdminNavbar() {
  const authUser = React.useContext(AuthUserContext);
  return (
    <NavAppBar
      id="navbar-admin"
      position="static"
      navBackground="white"
      navLinks={[]}
      navMenuItems={[]}
      loading={authUser.isAuthenticating || !authUser.profileHasLoaded}
    />
  );
}
