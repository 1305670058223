// React
import React from "react";
// Constants
import * as routes from "../../constants/routes";
// Components
import NavAppBar from "./NavAppBar";

// Material UI
import {Settings} from "@material-ui/icons";
import {useLocation} from "@reach/router";
import {AuthUserContext} from "../Session";
import queryString from "query-string";

function getNavLinks(location) {
  return [
    {
      id: routes.clientTeamsTab.replace(":tab", "contracts"),
      text: "Teams",
      isSelected: location.pathname.includes(routes.clientTeams),
      route: routes.clientTeamsTab.replace(":tab", "contracts"),
    },
    {
      id: routes.clientBills,
      text: "Bills",
      isSelected: location.pathname.includes(routes.clientBills),
      route: routes.clientBills,
    },
  ];
}

// // This is the bottom navigation for managers
// function getBottomList(location) {
//   return [
//     // {
//     //   id: "help-menu-item",
//     //   icon: <HelpIcon/>,
//     //   text: "Help",
//     //   route: "/help",
//     //   isDisabled: false
//     // },
//     {
//       id: "feedback-menu-item",
//       icon: <Feedback/>,
//       text: "Feedback",
//       route: location.pathname,
//       toggleParam: "feedback",
//       isDisabled: false,
//     },
//   ];
// }

function getNavMenuItems(authUser, parsedQueries, showBusinessSelector) {
  return Boolean(showBusinessSelector) ? (
    [
      {
        id: "settings-menu-item",
        icon: <Settings/>,
        text: "Account settings",
        route: queryString.stringifyUrl({
          url: routes.clientSettingsTab.replace(":tab", "account"),
          query: parsedQueries,
        }),
      },
    ]
  ) : [];
}

export default function ManagerNavbar(props) {
  const {showBusinessSelector} = props;
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);
  const authUser = React.useContext(AuthUserContext);
  const navLinks = getNavLinks(location);
  const navMenuItems = getNavMenuItems(authUser, parsedQueries, showBusinessSelector);

  return (
    <NavAppBar
      id="navbar-manager"
      position="static"
      navBackground="white"
      navLinks={navLinks}
      navMenuItems={navMenuItems}
      showBusinessSelector={showBusinessSelector}
      hideNavLinks={!showBusinessSelector}
      loading={authUser.isAuthenticating || !authUser.profileHasLoaded}
    />
  );
}

