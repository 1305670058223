// React
import React from "react";
import {navigate} from "gatsby";

// Constants
import * as routes from "../../../constants/routes";

// Components
import DrawerMenu from "./DrawerMenu";
import Link from "../../Link";

// Material ui
import {
  IconButton,
  AppBar,
  Toolbar,
  Box,
  Hidden,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import {withAuthentication} from "../../Session"; //AuthUserContext

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    background: "white",
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
  },
  toolbar: {
    justifyContent: "space-between",
  },
  logoBtn: {
    display: 'flex',
    border: "none",
    background: "transparent",
  },
  logo: {
    // width: 130,
    height: 25,
    cursor: "pointer",
  },
  aHrefElement: {
    textDecoration: "none",
  },
  divider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: "rgba(0, 0, 0, .2)"
  }
}));

function PublicNavbar(props) {
  const classes = useStyles();
  const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);
  // const authUser = React.useContext(AuthUserContext);

  const handleDrawerToggle = () => setDrawerIsOpen(!drawerIsOpen);

  return (
    <AppBar id="navbar" position="sticky" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <button
          onClick={() => navigate(routes.home)}
          className={classes.logoBtn}
        >
          <img
            id="logo"
            alt="Maxer logo full"
            className={classes.logo}
            // src='https://images.ctfassets.net/h6w739vsk2p5/sMwGfEjiM5WF3tLAOhdQs/dcf52de85543fede011553ee240ee3d8/maxer-ripple2.svg'
            // src="https://images.ctfassets.net/h6w739vsk2p5/7H8uo6N7BxSNOB4OLrGabL/a3b4e35042a600b10d8f67f8837df379/maxer-ripple.svg"
            src="https://images.ctfassets.net/h6w739vsk2p5/6fsAYtWPh5dlFV9iJOp8ly/a8c4164d358e83739fa7fa3254549128/maxxer-logo-full-svg.svg"
          />
        </button>
        <Hidden smUp>
          <Box>
            <DrawerMenu
              isOpen={drawerIsOpen}
              toggleDrawer={handleDrawerToggle}
            />
            <IconButton
              id="menu-btn"
              aria-label="Menu"
              onClick={handleDrawerToggle}
              style={{marginLeft: 7}}
            >
              <MenuIcon/>
            </IconButton>
          </Box>
        </Hidden>
        <Hidden xsDown>
          <Box style={{
            // width: 'fit-content',
            display: "flex",
            // '& svg': {
            //   margin: 12,
            // },
            '& hr': {
              margin: 0.5,
            },
          }}>
            {/*<Link*/}
            {/*  gutterSpacingRight={2}*/}
            {/*  variant="body1"*/}
            {/*  to={routes.pricing}*/}
            {/*>*/}
            {/*  Pricing*/}
            {/*</Link>*/}
            {/*<Divider*/}
            {/*  orientation="vertical"*/}
            {/*  className={classes.divider}*/}
            {/*  flexItem*/}
            {/*/>*/}
            <Link
              gutterSpacingLeft={2}
              variant="body1"
              to={routes.login}
            >
              Log in
            </Link>
            <Link
              color="primary"
              weight="bold"
              gutterSpacingLeft={2}
              variant="body1"
              to={routes.signup}
            >
              Sign up
            </Link>
          </Box>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

export default withAuthentication(PublicNavbar)

PublicNavbar.propTypes = {};
