// React
import React from "react";
import PropTypes from "prop-types";
import {navigate} from "gatsby";

// Material UI
import {makeStyles} from "@material-ui/core/styles";
import {
  Drawer,
  Button,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import {Clear} from "@material-ui/icons";

// Constants
import * as routes from "../../../constants/routes";

const useStyles = makeStyles(() => ({
  rootPaper: {
    // background: theme.palette.primary.dark,
    maxWidth: "80%",
  },
  divider: {
    marginTop: 8,
    marginBottom: 8,
  },
  listItemHeader: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  list: {
    width: 250,
    maxWidth: "100%",
  },
  listItemText: {
    // color: 'white',
    marginLeft: 4,
  },
  clearIcon: {
    // color: 'white',
    marginTop: 12,
    marginBottom: 12,
    cursor: "pointer",
  },
  aHrefElement: {
    textDecoration: "none",
    width: "100%",
  },
  signInButton: {
    width: "100%",
    marginLeft: 4,
    marginRight: 4,
    // marginTop: 10,
    // color: 'white',
    // borderColor: 'white',
  },
}));


export default function DrawerMenu(props) {
  const {isOpen, toggleDrawer} = props;
  const classes = useStyles();
  return (
    <Drawer
      id="drawer-menu"
      classes={{
        paper: classes.rootPaper,
      }}
      anchor="right"
      open={isOpen}
      onClose={toggleDrawer}
    >
      <List className={classes.list}>
        <ListItem key="logo" classes={{root: classes.listItemHeader}}>
          <ListItemIcon>
            <Clear className={classes.clearIcon} onClick={toggleDrawer}/>
          </ListItemIcon>
        </ListItem>
        <Divider className={classes.divider}/>
        <ListItem onClick={() => navigate(routes.home)} button key="home">
          <ListItemText
            classes={{primary: classes.listItemText}}
            primary="Home"
          />
        </ListItem>
        <ListItem
          id="apply-as-freelancer-listitem"
          onClick={() => navigate(routes.freelancers)}
          button
          key="freelancer-apply"
        >
          <ListItemText
            classes={{primary: classes.listItemText}}
            primary="Apply as a freelancer"
          />
        </ListItem>
        <ListItem key="login">
          <Button
            id="login-btn"
            variant="outlined"
            size="large"
            color="primary"
            className={classes.signInButton}
            onClick={() => navigate(routes.login)}
          >
            Log in
          </Button>
        </ListItem>
        <ListItem key={6}>
          <Button
            id="signup-btn"
            variant="contained"
            size="large"
            color="primary"
            className={classes.signInButton}
            onClick={() => navigate(routes.signup)}
          >
            Sign up
          </Button>
        </ListItem>
      </List>
    </Drawer>
  );
}

DrawerMenu.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
