// React
import React from "react";
import {navigate} from "gatsby";
import {useLocation} from "@reach/router";

import {BusinessContext} from "../../../components/Session";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import {
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  Divider,
  Button,
  IconButton,
  Tooltip
} from "@material-ui/core";
import {Add, Settings} from "@material-ui/icons";
import {Skeleton} from "@material-ui/lab";

// Constants
import * as routes from "../../../constants/routes";

// Other libs
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  skeletonRoot: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  root: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: theme.palette.borders,
    borderRadius: "0.2rem",
    display: "flex",
    justifyContent: "space-between"
  },
  formControl: {
    width: "100%",
  },
  // BusinessDropdown: {
  //   padding: 10,
  // },
  businessAvatar: {
    marginLeft: 5,
    width: 30,
    height: 30,
    marginRight: 0,
    fontSize: "1rem"
  },
  businessLogoContainer: {
    marginRight: 0,
  },
  manageBusinessesButton: {
    display: "flex",
    margin: "auto",
  },
}));

export default function BusinessSelector(props) {
  const {selectedBusiness, isFetching} = React.useContext(BusinessContext);

  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);

  const classes = useStyles();

  const handleChange = (event) => {
    const newQueries = {
      business: event.target.value,
      drawer: parsedQueries.drawer,
    };
    return navigate(
      queryString.stringifyUrl({url: location.pathname, query: newQueries})
    );
  };

  const handleAddBusiness = () => {
    return navigate(routes.clientCreateBusiness);
  };

  const handleSettingsClick = () =>
    navigate(
      queryString.stringifyUrl({url: routes.clientSettingsTab.replace(":tab", "business"), query: parsedQueries})
    );

  if (isFetching) {
    return (
      <Skeleton 
        variant="rect" 
        width={120} 
        height={32} 
        className={classes.skeletonRoot}
      />
    )
  }

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <Select
          disableUnderline={true}
          value={selectedBusiness ? selectedBusiness.uid : ""}
          onChange={handleChange}
          classes={{root: classes.BusinessDropdown}}
        >
          <MenuItem value={selectedBusiness ? selectedBusiness.uid : "loading"} key="loading">
            <ListItemText
              primary={selectedBusiness.name}
              style={{margin: "auto", paddingLeft: "8px"}}
              primaryTypographyProps={{
                variant: "caption"
              }}
            />
          </MenuItem>
          <Divider style={{marginTop: 10, marginBottom: 10}}/>
          <Button
            className={classes.manageBusinessesButton}
            onClick={handleAddBusiness}
            size="large"
            key="manageBusinesses"
            color="primary"
            disabled={true}
            startIcon={<Add/>}
          >
            Add business (coming soon)
          </Button>
        </Select>
      </FormControl>
      <Tooltip title="Business settings">
        <IconButton size="small" style={{width: 28, height: 28, margin: "auto 4px auto 4px"}}
                    onClick={handleSettingsClick}>
          <Settings style={{width: 18, height: 18, margin: "auto"}}/>
        </IconButton>
      </Tooltip>
    </div>
  );
}