// React
import React from "react";
import PropTypes from "prop-types";

// Context
import {FirebaseContext} from "../Firebase";
import {AuthUserContext} from "../Session";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DoneOutline from "@material-ui/icons/DoneOutline";

// Components
import DialogContainer from "../DialogContainer";
import SelectorTextfield from "../SelectorTextfield";
import EmptyStateView from "../EmptyStateView";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
  },
  dialogActionsRoot: {
    margin: "0px 20px 24px 20px",
  },
}));

const characterLimit = 1000;

export default function FeedbackDialog(props) {
  const {open, handleToggle} = props;

  const [feedbackText, setFeedbackText] = React.useState("");
  const [feedbackSubmitted, setFeedbackSubmitted] = React.useState(false);

  const classes = useStyles();
  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);

  const handleChange = (prop, value) =>
    setFeedbackText(value.substring(0, characterLimit));

  const handleSubmitClick = () => {
    firebase
      .userFeedback()
      .add({
        createdAt: firebase.createTimestamp(),
        lastUpdatedAt: firebase.createTimestamp(),
        uid: authUser.uid,
        role: authUser.role,
        text: feedbackText,
      })
      .then(() => setFeedbackSubmitted(true));
  };

  const handleClose = () => {
    handleToggle("feedbackDialogOpen");
    setFeedbackSubmitted(false);
    setFeedbackText("");
  };

  return (
    <DialogContainer
      open={open}
      title={feedbackSubmitted ? "Feedback submitted" : "Provide feedback"}
      fullScreen={false}
      onClose={handleClose}
      classes={{root: classes.root, paper: classes.paper}}
      dialogActions={
        !feedbackSubmitted && (
          <div>
            <Button
              onClick={handleClose}
              color="primary"
              style={{marginRight: 10}}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmitClick}
              color="primary"
              variant="contained"
            >
              Submit feedback
            </Button>
          </div>
        )
      }
    >
      <DialogContent>
        {feedbackSubmitted ? (
          <EmptyStateView
            text="Thank you for helping us improve Maxer"
            icon={<DoneOutline/>}
          />
        ) : (
          <SelectorTextfield
            id="Feedback"
            label="Feedback"
            value={feedbackText}
            formHelperText="Report a bug you have encountered or suggest a new feature"
            handleChange={handleChange}
            multiline={true}
            rowsMax={10}
            nrOfRows={5}
            characterLimit={characterLimit}
          />
        )}
      </DialogContent>
    </DialogContainer>
  );
}

FeedbackDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
};
