// Source: https://stackoverflow.com/a/59590002/3793914
export function camelToSentenceText(str) {
  let lowerCaseWithSpaces = str
    .replace(/([A-Z])/g, " $1")
    .toLowerCase()
    .trim();
  return capitalizeFirstLetter(lowerCaseWithSpaces)
}

export function capitalizeFirstLetter(str) {
  const firstLetter = str.substring(0, 1).toUpperCase();
  const otherLetters = str.substring(1, str.length);
  return `${firstLetter}${otherLetters}`;
}

export function numberWithCommas(str) {
  return str ? str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
}

export function getCurrencyFormat(amount, currency, options) {
  // Note: By setting locale to undefined the locale on the users browser is used
  const amountFormatted =
    options && options.convertToDecimals
      ? formatCentsToDisplayPrice(amount)
      : amount;
  let formatted = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency,
  }).format(amountFormatted);

  // If the string ends with .00 then strip that part away
  if (formatted.substring(formatted.length - 3, formatted.length) === ".00") {
    return formatted.substring(0, formatted.length - 3);
  }
  return formatted;
}

export function getCurrencySymbol(currency, stripCode) {
  // Eg. US$0.00
  return getCurrencyFormat(0, currency)
    .replace("0.00", "")
    .replace("0", "")
    .replace(stripCode ? currency : "", "")
    .trim();
}

export function countryToFlag(isoCode) {
  if (!isoCode) {
    return ""
  }
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

// // Utils for converting floats to int values and vice-versa

export function formatPriceToCents(value) {
  value = (value + "").replace(/[^\d.-]/g, "");
  if (value && value.includes(".")) {
    value = value.substring(0, value.indexOf(".") + 3);
  }
  return value ? Number(Math.round(parseFloat(value) * 100)) : 0;
}

export function formatCentsToDisplayPrice(value) {
  value = (value + "").replace(/[^\d.-]/g, "");
  value = parseFloat(value);
  return value ? value / 100 : 0;
}

// START goal value formatting

export function getThousandsAbbreviated(value) {
  // Value is retrieved at comma seperated string so should be parsed back to int first
  const num = parseInt(value.replace(",", ""));
  return `${numberWithCommas(num >= 10000 ? num / 1000 : num)}${num >= 10000 ? "k" : ""}`
}

export function forceTrailingSlash(str) {
  const lastChar = str.substr(-1);
  if (lastChar !== '/') {
    return str + "/"
  }
  return str;
}