// React
import React from "react";
import PropTypes from "prop-types";
import {useLocation} from "@reach/router";

// Components
import Link from "../../Link";
import NavAvatarMenu from "./NavAvatarMenu";
import {AuthUserContext} from "../../Session";
import BusinessSelector from "./BusinessSelector";
import FeedbackDialog from "../../FeedbackDialog";

// Material ui
import {AppBar, Toolbar, Hidden, Button} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import {makeStyles} from '@material-ui/core/styles';
import {Feedback} from "@material-ui/icons";
import queryString from "query-string";

// Constants
import * as routes from "../../../constants/routes";

function getBackgroundColor(navBackground, theme) {
  if (navBackground === "white") {
    return "white"//`rgba(36, 41, 47, 0.1)`
  }
  if (navBackground === "darkBlue") {
    return theme.palette.background.dark
  }
  if (navBackground === "blue") {
    return theme.palette.primary.light
  }
  return "white"
}

const useStyles = makeStyles((theme) => ({
  root: ({navBackground}) => ({
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "0 6px 6px 0 rgba(0,0,0,.02)",
    background: getBackgroundColor(navBackground, theme),
    borderBottom: navBackground === "white" ? `1px solid ${theme.palette.borders}` : "none",
  }),
  toolbar: {
    justifyContent: "space-between",
    paddingLeft: 16,
    paddingRight: 16,
  },
  logo: {
    verticalAlign: "middle",
    marginTop: "auto",
    marginBottom: "auto",
    paddingLeft: theme.spacing(1),
    height: 32,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.85,
    },
  },
  alignVertically: {
    display: 'flex',
    marginTop: "auto",
    marginBottom: "auto",
  },
  container: {
    display: "flex",
  },
  feedbackBtn: {
    marginRight: theme.spacing(2),
    marginTop: 'auto',
    marginBottom: "auto",
  }
}));

export default function NavAppBar(props) {
  const {
    id,
    position,
    navLinks,
    hideNavLinks,
    navMenuItems,
    toolbarVariant,
    showBusinessSelector,
    loading
  } = props;
  const [avatarAnchorEl, setAvatarAnchorEl] = React.useState(null);
  const [feedbackDialOpen, setFeedbackDialOpen] = React.useState(false);
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);
  const classes = useStyles(props);
  const authUser = React.useContext(AuthUserContext);

  const handleFeedbackToggle = () => setFeedbackDialOpen(current => !current);

  const handleToggleNavAvatarMenu = (event) =>
    setAvatarAnchorEl(avatarAnchorEl ? null : event.currentTarget);

  return (
    <div>
      <FeedbackDialog
        open={feedbackDialOpen}
        handleToggle={handleFeedbackToggle}
      />
      <AppBar
        id={id}
        position={position}
        className={classes.root}
      >
        <Toolbar className={classes.toolbar} variant={toolbarVariant || "regular"}>
          <div className={classes.container}>
            {/*{authUser.status !== "profileIncomplete" && (*/}
            {/*  <Hidden smUp>*/}
            {/*    <IconButton*/}
            {/*      id="menu-btn"*/}
            {/*      color="inherit"*/}
            {/*      onClick={() => handleParamToggle("drawer")}*/}
            {/*      style={{color: "black"}}*/}
            {/*    >*/}
            {/*      <MenuIcon/>*/}
            {/*    </IconButton>*/}
            {/*  </Hidden>*/}
            {/*)}*/}
            <Link to={routes.app}>
              <img
                alt="Maxer logo rounded black"
                src="https://images.ctfassets.net/h6w739vsk2p5/5yodIbk23goEcnxPPhZtje/df6e51724a7a05f2729e3d27fa6a40e8/maxer-logo-app.svg"
                className={classes.logo}
              />
            </Link>

              
            {showBusinessSelector && (
              <BusinessSelector/>
            )}
            <Hidden xsDown smUp={hideNavLinks}>
              <div className={classes.alignVertically}>
                {navLinks.map((navLink, idx) => {
                  const {route, isSelected, text} = navLink;
                  const gutterSpacingLeft = idx === 0 ? 3 : 1.5;
                  const gutterSpacingRight = 1.5;
                  return loading ? (
                    <Skeleton 
                      variant="rect" 
                      width={60} 
                      height={16} 
                      style={{ marginLeft: gutterSpacingLeft * 8, marginRight: gutterSpacingRight * 8 }}
                    />
                  ) : (
                    <Link
                      key={route}
                      color={isSelected ? "primary" : "inherit"}
                      weight="bold"
                      gutterSpacingLeft={gutterSpacingLeft}
                      gutterSpacingRight={gutterSpacingRight}
                      variant="body2"
                      to={`${route}${parsedQueries.business ? `?business=${parsedQueries.business}` : ''}`}
                    >
                      {text}
                    </Link>
                  )
                })}
              </div>
            </Hidden>
          </div>

          <div className={classes.container}>
            {loading ? (
              <Skeleton 
                variant="rect" 
                width={60} 
                height={16} 
                className={classes.feedbackBtn}
              />
            ) : (
              <Button
                onClick={handleFeedbackToggle}
                size="small"
                startIcon={<Feedback/>}
                className={classes.feedbackBtn}
              >
                Feedback
              </Button>
            )}
            {loading ? ( 
              <Skeleton 
                variant="circle" 
                width={32} 
                height={32} 
              />
            ) : (
              <NavAvatarMenu
                profilePhoto={authUser.profilePhoto}
                menuItems={navMenuItems}
                firstName={authUser.firstName}
                lastName={authUser.lastName}
                avatarAnchorEl={avatarAnchorEl}
                handleToggle={handleToggleNavAvatarMenu}
              />
            )}
          </div>
          
        </Toolbar>
      </AppBar>
      {/*{parsedQueries.drawer === "1" && (*/}
      {/*  <Hidden smUp>*/}
      {/*    <NavDrawer>*/}
      {/*      <NavDrawerMenu*/}
      {/*        handleToggle={() => handleParamToggle("drawer")}*/}
      {/*        topList={getTopDrawerList(location)}*/}
      {/*        bottomList={getBottomList(location)}*/}
      {/*      />*/}
      {/*    </NavDrawer>*/}
      {/*  </Hidden>*/}
      {/*)}*/}
    </div>
  );
}

// App bar support three background colors
const navBackgrounds = {
  white: "white",
  darkBlue: "dark",
  blue: "blue",
};

NavAppBar.propTypes = {
  id: PropTypes.string.isRequired,
  position: PropTypes.string,
  background: PropTypes.string,
  navLinks: PropTypes.array.isRequired,
  navMenuItems: PropTypes.array.isRequired,
  navBackground: PropTypes.oneOf(Object.keys(navBackgrounds)).isRequired,
};
