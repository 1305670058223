// React
import React from "react";
import PropTypes from "prop-types";
import {navigate} from "gatsby";

// Components
import {AuthUserContext} from "../../Session";

// Utils
import {Avatar, Tooltip, Menu, MenuItem, IconButton, Divider, Typography} from "@material-ui/core";
import {ArrowDropDown, PowerSettingsNew} from "@material-ui/icons";
import {makeStyles} from '@material-ui/core/styles';
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  iconButton: {
    color: "white",
    boxShadow: "none",
    width: 32,
    height: 32,
  },
  avatar: {
    background: theme.palette.background.dark,
    width: 32,
    height: 32,
  },
  arrowDropIcon: {
    color: "black",
    verticalAlign: "middle",
    margin: "auto",
  },
  menuItem: {
    minWidth: 180,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  logoutItem: {
    marginTop: theme.spacing(1),
  },
  menuItemText: {
    paddingLeft: theme.spacing(2),
  }
}));

export default function NavAvatarMenu(props) {
  const {
    profilePhoto,
    firstName,
    lastName,
    menuItems,
    handleToggle,
    avatarAnchorEl,
  } = props;
  const authUser = React.useContext(AuthUserContext);
  const classes = useStyles(props);

  const handleMenuItemClick = route => navigate(route);

  return (
    <div>
      <div className={classes.root}>
        <Tooltip title={`Signed in as ${firstName} ${lastName}`}>
          <IconButton
            className={classes.iconButton}
            size="small"
            onClick={(event) => handleToggle(event)}
            disabled={Boolean(avatarAnchorEl)}
          >
            {profilePhoto ? (
              <Avatar className={classes.avatar} src={profilePhoto}/>
            ) : (
              <Avatar className={classes.avatar}>{firstName.substring(0, 1).toUpperCase()}</Avatar>
            )}
          </IconButton>
        </Tooltip>
        <ArrowDropDown className={classes.arrowDropIcon}/>
      </div>
      <Menu
        id="nav-menu-dropdown"
        elevation={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={avatarAnchorEl}
        open={Boolean(avatarAnchorEl)}
        onClose={handleToggle}
      >
        {menuItems.map(({icon, text, route}) =>
          <MenuItem onClick={() => handleMenuItemClick(route)} key={route} className={classes.menuItem}>
            {icon}
            <Typography className={classes.menuItemText}>{text}</Typography>
          </MenuItem>
        )}
        <Divider sx={{my: 0.5}}/>
        <MenuItem 
          onClick={() => authUser.logout()} 
          className={classNames(classes.menuItem, classes.logoutItem)}
          key="logout"
        >
          <PowerSettingsNew/>
          <Typography className={classes.menuItemText}>Log out</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

NavAvatarMenu.propTypes = {
  profilePhoto: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  menuItems: PropTypes.array,
  handleToggle: PropTypes.func.isRequired,
  avatarAnchorEl: PropTypes.object,
};
