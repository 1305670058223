// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";

// Utils
import {numberWithCommas} from "../../utils/formatting";

const useStyles = makeStyles((theme) => ({
  root: ({topGutterSpacing, bottomGutterSpacing}) => ({
    marginTop: theme.spacing(topGutterSpacing || 0),
    marginBottom: theme.spacing(bottomGutterSpacing || 0),
    width: "100%",
  }),
  formHelperText: {
    marginBottom: 15,
  },
}));

export default function SelectorTextfield(props) {
  const {
    id,
    variant,
    label,
    value,
    handleChange,
    multiline,
    nrOfRows,
    rowsMax,
    placeholder,
    characterMinimum,
    characterLimit,
    formHelperText,
    inputProps,
    error,
  } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {formHelperText && (
        <FormHelperText className={classes.formHelperText}>
          {formHelperText}
        </FormHelperText>
      )}
      <TextField
        id={id}
        label={label}
        fullWidth={true}
        className={classes.textField}
        value={value}
        onChange={(e) => handleChange(id, e.target.value)}
        placeholder={placeholder || ""}
        variant={variant || "outlined"}
        multiline={multiline}
        rows={nrOfRows ? nrOfRows : 1}
        rowsMax={rowsMax ? rowsMax : !multiline ? 1 : 1000}
        error={error || (characterMinimum && value.length < characterMinimum)}
        helperText={
          Boolean(characterMinimum)
            ? Boolean(value.length < characterMinimum)
            ? `Write at least ${String(
              characterMinimum - value.length
            )} characters more`
            : `${numberWithCommas(
              value ? String(characterLimit - value.length) : characterLimit
            )} characters remaining`
            : value.length >= characterLimit ? (
              `Cannot be longer than ${numberWithCommas(characterLimit)} characters`
            ) : null
        }
        // autoFocus={autoFocus}
        InputProps={{...inputProps}}
      />
    </div>
  );
}

SelectorTextfield.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  formHelperText: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  rowsMax: PropTypes.number,
  nrOfRows: PropTypes.number,
  characterMinimum: PropTypes.number,
  characterLimit: PropTypes.number.isRequired,
  inputProps: PropTypes.object,
  error: PropTypes.bool,
};
