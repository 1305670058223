// React
import React from "react";
import {useLocation} from "@reach/router";

// Constants
import * as routes from "../../constants/routes";
// Components
import NavAppBar from "./NavAppBar";
// Material ui
import {Receipt, Settings} from "@material-ui/icons";
import {AuthUserContext} from "../Session";

function getNavLinks(location) {
  return [
    {
      id: routes.freelancerTeams,
      text: "Teams",
      isSelected: location.pathname.includes(routes.freelancerTeams),
      route: routes.freelancerTeams,
    },
    {
      id: routes.freelancerContracts,
      text: "Contracts",
      isSelected: location.pathname.includes(routes.freelancerContracts),
      route: routes.freelancerContractsTab.replace(":tab", "hire"),
    },
    {
      id: routes.freelancerLeads,
      text: "Leads",
      isSelected: location.pathname.includes(routes.freelancerLeads),
      route: routes.freelancerLeadsTab.replace(":tab", "requests"),
    },
    {
      id: routes.freelancerBills,
      text: "Bills",
      isSelected: location.pathname.includes(routes.freelancerBills),
      route: routes.freelancerBills,
    },
  ];
}

// // This is the bottom navigation for managers
// function getBottomList(location) {
//   return [
//     // {
//     //   id: "help-menu-item",
//     //   icon: <HelpIcon/>,
//     //   text: "Help",
//     //   route: "/help",
//     //   isDisabled: false
//     // },
//     {
//       id: "feedback-menu-item",
//       icon: <Feedback/>,
//       text: "Feedback",
//       route: location.pathname,
//       toggleParam: "feedback",
//       isDisabled: false,
//     },
//   ];
// }

function getNavMenuItems(authUser) {
  return Boolean(authUser.status !== "profileIncomplete") ? (
    [
      {
        id: "invoices-menu-item",
        icon: <Receipt/>,
        text: "Invoices",
        route: routes.freelancerInvoices,
      },
      {
        id: "settings-menu-item",
        icon: <Settings/>,
        text: "Settings",
        route: routes.freelancerSettings,
      },
    ]
  ) : [];
}

export default function FreelancerNavbar() {
  const location = useLocation();
  const authUser = React.useContext(AuthUserContext);
  const navLinks = getNavLinks(location);
  const navMenuItems = getNavMenuItems(authUser);

  return (
    <NavAppBar
      id="navbar-freelancer"
      position="static"
      navBackground="white"
      navLinks={navLinks}
      navMenuItems={navMenuItems}
      hideNavLinks={authUser.status === "profileIncomplete"}
      loading={authUser.isAuthenticating || !authUser.profileHasLoaded}
    />
  );
}
