// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import {hexToRgb} from "../../utils/colors";

const useStyles = makeStyles((theme) => ({
  root: ({ topSpacing, topGutterSpacing }) => ({
    width: "100%",
    display: "inline-grid",
    marginTop: theme.spacing(topGutterSpacing || 0),
    paddingTop: theme.spacing(topSpacing || 5),
    paddingBottom: theme.spacing(topSpacing || 5),
    background: `rgba(${hexToRgb(theme.palette.primary.main)}, 0.05)`,
    borderRadius: "0.9rem",
  }),
  iconContainer: {
    margin: "auto",
    color: theme.palette.primary.light,
    opacity: 0.6,
    fontSize: 60,
  },
  text: {
    marginTop: theme.spacing(1),
    textAlign: "center",
    margin: "auto",
    opacity: 0.6,
    maxWidth: 320,
  },
  button: {
    width: "fit-content",
    marginTop: 15,
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export default function EmptyStateView(props) {
  const {icon, text, buttonIconLeft, buttonText, onButtonClick} = props;
  const classes = useStyles(props);

  return (
    <div
      className={classes.root}
      id="empty-state-view"
    >
      {icon && (
        <SvgIcon className={classes.iconContainer}>
          {icon}
        </SvgIcon>
      )}
      <Typography className={classes.text} variant="caption">
        {text}
      </Typography>
      {buttonText && onButtonClick && (
        <Button
          onClick={onButtonClick}
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          {buttonIconLeft} {buttonText}
        </Button>
      )}
    </div>
  );
}

EmptyStateView.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string.isRequired,
  buttonIconLeft: PropTypes.object,
  buttonText: PropTypes.string,
};
